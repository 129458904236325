import React from "react"
import { graphql } from "gatsby"
// import rehypeReact from "rehype-react"
// import Gallery from "../components/galleryWidget/gallery"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Page,
  MajorColumn,
  MinorColumn,
  FlexWrapMobile,
  AdaptiveLink,
  NextPrevButton
} from "../components/content-elements"
import { AddThisInlineShare } from "../components/addthis_inline"

import BlogListing from "../components/blog_listing"
// import GalleryListing from "../components/gallery_listing"

const BlogPostTemplate = ({ data, location }) => {
  const { post, previous, next, blogs } = data

// console.log(post)
  // const renderAst = new rehypeReact({
  //     createElement: React.createElement,
  //     components: {
  //       "photo-gallery": Gallery
  //       //p: Paragraph
  //     }
  //   }
  // ).Compiler

  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <FlexWrapMobile>


        <MajorColumn>
          <Page page={post} seo={true} />

            <section itemProp="articleNavigation">
              <ul className={`py-16 flex justify-between`}>
                <NextPrevButton>
                  {previous && (
                    <AdaptiveLink to={`/${previous.fields.slug}`} rel="prev">
                      ← {previous.frontmatter.title}
                    </AdaptiveLink>
                  )}
                </NextPrevButton>
                <NextPrevButton>
                  {next && (
                    <AdaptiveLink to={`/${next.fields.slug}`} rel="next">
                      {next.frontmatter.title} →
                    </AdaptiveLink>
                  )}
                </NextPrevButton>
              </ul>
            </section>
        </MajorColumn>

        <MinorColumn>
          <AddThisInlineShare />
          <BlogListing data={blogs} columns={2} />
        </MinorColumn>

      </FlexWrapMobile>

    </Layout>
  )
}

export default BlogPostTemplate


export const pageQuery = graphql`
    query BlogPostBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        post: markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            htmlAst
            html
            fields {
                slug
            }
            frontmatter {
                title
                image
                subtitle
                date(formatString: "MMMM DD, YYYY")
                description
                tags
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        ...BlogListFragment
    }
`
